import React from 'react';

// MUI functions
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  makeStyles, useTheme,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import SVGArt from './svgart';

const useStyles = makeStyles((theme) => ({
  diagonalSvg: {
    position: 'absolute',
    bottom: -1,
    left: 0,
    width: '100%',
    height: '4vw',
    zIndex: 5,
  },
  photoHeaderDiv: {
    position: 'relative',
    width: '100%',
  },
  brandHeaderDiv: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    // height: (props: StyleProps) => props.containerHeight,
    zIndex: 5,
    display: 'flex',
    alignItems: 'center',
  },
  headline: {
    color: theme.palette.primary.contrastText,
  },
  brandHeader: {
    zIndex: 5,
    // marginLeft: theme.spacing(10),
  },
  photo: {
    minHeight: 150,
  },
  trianglifySVG: {
    width: '100%',
    height: '20vh',
  },
  container: {
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    borderLeftColor: '#fff',
    borderLeftStyle: 'solid',
    borderLeftWidth: theme.spacing(1),
  },
}));

interface Props {
  title: string
  subtitle?: string
  smallFont?: boolean
  borderLeft?: boolean
}

const defaultProps = {
  subtitle: null,
  smallFont: false,
  borderLeft: false,
};
const PhotoHeader = ({
  title, subtitle, smallFont, borderLeft,
}: Props) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const parsedSmallFont = smallFont || xs;
  const classes = useStyles({
    fontSize: parsedSmallFont ? '1rem' : '3rem',
  });

  return (
    <div className={classes.photoHeaderDiv}>
      <SVGArt
        height="20vh"
        width="100%"
        ratio={10}
      />
      <div className={classes.brandHeaderDiv}>
        <Container className={classes.brandHeader}>
          <div className={classes.container}>
            <Typography
              variant={parsedSmallFont ? 'h5' : 'h3'}
              className={classes.headline}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.headline}
            >
              {subtitle}
            </Typography>
          </div>
        </Container>
      </div>
    </div>
  );
};

PhotoHeader.defaultProps = defaultProps as unknown as Partial<Props>;

export default PhotoHeader;
