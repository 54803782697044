import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from "@reach/router"

// MUI functions
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  makeStyles, useTheme, darken,
} from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { useScrollYPosition } from '../hooks';
import Link from './CleanLink';

import LogoHalfBlack from '../brand/wxd_logo_halfblack.png';
import LogoHalfWhite from '../brand/wxd_logo_halfwhite.png';

const useStyles = makeStyles((theme) => ({
  appbar: {
  },
  appbarNormal: {
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
  },
  appbarTransparent: {
    backgroundColor: 'transparent',
    color: '#fff',
    boxShadow: 'none',
  },
  linkButtonHome: {
    display: 'flex',
    alignItems: 'center',
  },
  flexElement: {
    flexGrow: 1,
  },
  navigationDiv: {
    boxShadow: '4px 4px 5px 0px rgba(0,0,0,0.3)',
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 5000,
    padding: theme.spacing(1),
  },
  navigationDivFlexBoxDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  navigationDivFlexBoxDivItem: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  whiteText: {
    color: '#fff',
  },
  navbtn: {
    marginLeft: theme.spacing(3),
  },
  list: {
    width: 250,
  },
  cleanLink: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  SignupBtn: {
    fontSize: 16,
    marginRight: theme.spacing(3),
    display: 'block',
    height: '100%',
    minWidth: '150px',
    fontWeight: 500,
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    // backgroundColor: 'transparent',
    // boxShadow: '8px 8px 0px 0px rgba(63, 81, 181, 1)',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRadius: '4px',
    borderColor: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: darken(theme.palette.success.main, 0.2),
      color: '#fff',
      borderBottomWidth: '2px',
      // borderBottom: 'none',
    },
  },
  LoginBtn: {
    color: 'inherit',
    fontSize: 16,
    marginRight: theme.spacing(3),
    display: 'block',
    minWidth: '150px',
    height: '100%',
    fontWeight: 400,
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: theme.spacing(1),
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRadius: '4px',
    borderColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.success.main, 0.2),
      color: '#fff',
      borderBottomWidth: '2px',
      // borderBottom: 'none',
    },
  },
}));

const Appbar = () => {
  const theme = useTheme();
  const location = useLocation();
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  // navbar color
  const scrollY = useScrollYPosition();
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const bigScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const yLim = bigScreen ? 100 : 10;
    const isShow = scrollY < yLim && location.pathname === '/';
    if (isShow !== hideOnScroll) setHideOnScroll(isShow);
  }, [scrollY, bigScreen, location.pathname]);

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(
          classes.appbar, hideOnScroll ? classes.appbarTransparent : classes.appbarNormal,
        )}
      >
        <Toolbar>
          <Link className={classes.linkButtonHome} to="/">
            <img
              src={hideOnScroll ? LogoHalfWhite : LogoHalfBlack}
              alt="Logo"
              height={xs ? '56px' : '64px'}
            />
          </Link>
          <div className={classes.flexElement} />
          <Typography color="error" variant="h6">
            This is a dummy site for the Collisio Technologies webscraping challenge.
          </Typography>
        </Toolbar>
      </AppBar>

    </>
  );
};

export default Appbar;
