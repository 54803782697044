import React from 'react';
import Highlight, { defaultProps, Language } from 'prism-react-renderer';
import vsDark from 'prism-react-renderer/themes/vsDark';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import LanguageColors from './languageColors.json';

const AppleTrafficLight = ({ classes }) => (
  <div className={classes.circleContainer}>
    <div className={clsx(classes.baseCircle, classes.redCircle)} />
    <div className={clsx(classes.baseCircle, classes.yellowCircle)} />
    <div className={clsx(classes.baseCircle, classes.greenCircle)} />
  </div>
);

const useLanguageBadgeStyles = makeStyles((theme) => ({
  language: {
    backgroundColor: (props) => props.bg,
    color: (props) => props.fg,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: -theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    // boxShadow: (props) => {
    //   const bigint = parseInt(props.bg.replace('#', ''), 16);
    //   const r = (bigint >> 16) & 255;
    //   const g = (bigint >> 8) & 255;
    //   const b = bigint & 255;
    //   return `5px 5px 4px 0px rgba(${r},${g},${b},0.4)`
    // }
  },
}));

const LanguageBadge = ({ language }: { language: string }) => {
  const classes = useLanguageBadgeStyles({
    fg: LanguageColors[language]?.fg || '#fff',
    bg: LanguageColors[language]?.bg || '#000',
  });
  return (
    <div className={classes.language}>
      <Typography variant="body1">
        {language}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  codeStyle: {
    '& > pre': {
      borderRadius: '10px',
      padding: theme.spacing(1.5),
      overflowX: 'auto',
      boxShadow: '4px 4px 5px 0px rgba(0,0,0,0.3)',
    },
  },
  circleContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  baseCircle: {
    marginRight: theme.spacing(1),
    height: theme.spacing(2),
    width: theme.spacing(2),
    borderRadius: '50%',
  },
  redCircle: {
    backgroundColor: '#ff5c5c',
    border: '1px solid #e33e41',
  },
  yellowCircle: {
    backgroundColor: '#ffbd4c',
    border: '1px solid #e09e3e',
  },
  greenCircle: {
    backgroundColor: '#00ca56',
    border: '1px solid #14ae46',
  },
  windowBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface InputTypes {
  children: string
  className: string
}

export default ({ children, className }: InputTypes) => {
  // Pull the className
  const language = (className.replace(/language-/, '') || '') as Language;
  const classes = useStyles();

  return (
    <Highlight
      {...defaultProps}
      code={children}
      language={language}
      theme={vsDark}
    >
      {({
        className, style, tokens, getLineProps, getTokenProps,
      }) => (
        <div className={classes.codeStyle}>
          <pre className={className} style={{ ...style }}>
            <div className={classes.windowBar}>
              <AppleTrafficLight classes={classes} />
              <LanguageBadge classes={classes} language={language} />
            </div>
            {tokens.map((line, index) => {
              const lineProps = getLineProps({ line, key: index });
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} {...lineProps}>
                  {line.map((token, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={key} {...getTokenProps({ token, key })} />
                  ))}
                </div>
              );
            })}
          </pre>
        </div>
      )}
    </Highlight>
  );
};
