import React, { useState } from 'react';
import Img, { FluidObject } from 'gatsby-image';

import { makeStyles } from '@material-ui/core/styles';
import {
  CardContent, Card, Typography, Button, CardActions,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    minWidth: '80vw',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: '95vh',
    overflowY: 'auto',
  },
  spacer: {
    minHeight: theme.spacing(4),
  },
  liveOffering: {
    borderRadius: '4px',
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    padding: theme.spacing(1),
  },
  expiredOffering: {
    borderRadius: '4px',
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    padding: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

interface LayoutRootProps {
  offering: {
    title: string;
    description: string;
    raised: string;
    investors: string;
    daysleft: string;
    mininvest: string;
    valuation: string;
    price_per_share: string;
    shares_offered: string;
    offering_type: string;
    offering: string;
    status: string;
    picture: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
  };
}

const OfferingCard: React.FC<LayoutRootProps> = ({
  offering,
}: LayoutRootProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Card className={classes.card}>
        <Img fluid={offering.picture.childImageSharp.fluid} alt="Logo" />
        <CardContent>
          <Typography variant="h5" color="primary">
            {offering.title}
          </Typography>
          <Typography variant="body1" id="description">
            {offering.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => setShowPopup(true)}>
            Mostrar más
          </Button>
        </CardActions>
      </Card>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showPopup}
        onClose={() => setShowPopup(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showPopup}>
          <div className={classes.paper}>
            <Img fluid={offering.picture.childImageSharp.fluid} alt="Logo" />
            <div className={classes.spacer} />
            <Typography variant="h3" color="primary">
              {offering.title}
            </Typography>
            <Typography variant="body1" id="description">
              {offering.description}
            </Typography>
            <div className={classes.spacer} />
            {offering.status === 'live'
              ? (
                <div className={classes.liveOffering}>
                  <Typography color="inherit" variant="caption">
                    LIVE
                  </Typography>
                </div>
              )
              : (
                <div className={classes.expiredOffering}>
                  <Typography color="inherit" variant="caption">
                    EXPIRED
                  </Typography>
                </div>
              )}
            <div className={classes.spacer} />
            <Typography color="primary" variant="h6">
              About this offering
            </Typography>
            {offering.investors && (
            <div className={classes.flex}>
              <span>Investors:</span>
              <span>{offering.investors}</span>
            </div>
            )}
            {offering.raised && (
            <div className={classes.flex}>
              <span>Raised:</span>
              <span>{`$${offering.raised}`}</span>
            </div>
            )}
            {offering.mininvest && (
            <div className={classes.flex}>
              <span>Minimum Investment:</span>
              <span>{`$${offering.mininvest}`}</span>
            </div>
            )}
            {offering.valuation && (
            <div className={classes.flex}>
              <span>Valuation:</span>
              <span>{`$${offering.valuation}`}</span>
            </div>
            )}
            <div className={classes.spacer} />
            <Typography color="primary" variant="h6">
              More fields
            </Typography>
            {offering.offering && (
            <div className={classes.flex}>
              <span>Regulation:</span>
              <span>{offering.offering}</span>
            </div>
            )}
            {offering.offering_type && (
            <div className={classes.flex}>
              <span>Security type:</span>
              <span>{`${offering.offering_type}`}</span>
            </div>
            )}
            {offering.shares_offered && (
            <div className={classes.flex}>
              <span>Shares offered:</span>
              <span>{`${offering.shares_offered}`}</span>
            </div>
            )}
            {offering.price_per_share && (
            <div className={classes.flex}>
              <span>Price per share:</span>
              <span>{`$${offering.price_per_share}`}</span>
            </div>
            )}
            <div className={classes.spacer} />
            <Button
              onClick={() => setShowPopup(false)}
              color="secondary"
              variant="contained"
              fullWidth
            >
              Cerrar
            </Button>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default OfferingCard;
