import React, { useEffect, useState } from 'react';

const toRad = (deg: number) => deg * (Math.PI / 180.0);

const divide = (count: number) => {
  const deg = 360 / count;

  return Array(count)
    .fill('a')
    .map((_, i) => i * deg);
};

const randomDoubleGenerator = (s) => {
  const mask = 0xffffffff;
  let m_w = (123456789 + s) & mask;
  let m_z = (987654321 - s) & mask;

  return function () {
    m_z = (36969 * (m_z & 65535) + (m_z >>> 16)) & mask;
    m_w = (18000 * (m_w & 65535) + (m_w >>> 16)) & mask;

    let result = ((m_z << 16) + (m_w & 65535)) >>> 0;
    result /= 4294967296;
    return result;
  };
};

const magicPoint = (value: number, min: number, max: number) => {
  let radius = min + value * (max - min);
  if (radius > max) {
    radius -= min;
  } else if (radius < min) {
    radius += min;
  }
  return radius;
};

const getPoint = (origin: [number, number], radius: number, degree: number) => {
  const x = origin[0] + radius * Math.cos(toRad(degree));
  const y = origin[1] + radius * Math.sin(toRad(degree));
  const returnObj:[number, number] = [Math.round(x), Math.round(y)];
  return returnObj;
};

const shuffle = (array: any[]) => {
  array.sort(() => Math.random() - 0.5);
  return array;
};

interface Props {
  point: [number, number]
  colorArray: string[]
}

const BlobItem = ({ point, colorArray }: Props) => {
  const [path, setPath] = useState('');
  const [color, setColor] = useState('');

  useEffect(() => {
    // generate points from midpoint
    const size = 75;
    const minGrowth = 4;
    const edgesCount = 9;
    const outerRad = size / 2;
    const innerRad = minGrowth * (outerRad / 10);

    const slices = divide(edgesCount);
    const maxRandomValue = shuffle([99, 999, 9999, 99999, 999999])[0];
    const id = Math.floor(Math.random() * maxRandomValue);
    const seedValue = id;
    const randVal = randomDoubleGenerator(seedValue);
    const destPoints:[number, number][] = [];

    slices.forEach((degree) => {
      const O = magicPoint(randVal(), innerRad, outerRad);
      const end = getPoint(point, O, degree);
      destPoints.push(end);
    });

    // create path from points
    let tempPath = '';
    const mid = [
      (destPoints[0][0] + destPoints[1][0]) / 2,
      (destPoints[0][1] + destPoints[1][1]) / 2,
    ];
    tempPath += `M${mid[0]},${mid[1]}`;

    destPoints.forEach((_point, i) => {
      const p1 = destPoints[(i + 1) % destPoints.length];
      const p2 = destPoints[(i + 2) % destPoints.length];
      const midPoint = [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2];
      tempPath += `Q${p1[0]},${p1[1]},${midPoint[0]},${midPoint[1]}`;
    });

    tempPath += 'Z';

    setPath(tempPath);
    setColor(colorArray[Math.floor(Math.random() * colorArray.length)]);
  }, [colorArray, point]);

  return (
    <path id={`blobby-${path}`} d={path} fill={color} filter="url(#shadow)" />
  );
};

export default BlobItem;
