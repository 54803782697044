import React, { ReactNode } from 'react';
import clsx from 'clsx';

// MUI functions
import {
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cntr: {
    width: '100%',
    minHeight: '95vh',
    [theme.breakpoints.up('sm')]: {
      marginTop: '64px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '56px',
    },
    display: 'flex',
    flexDirection: 'column',
  },
}));

const SafeAreaContainer = ({ children, className }: { children: ReactNode, className: string }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.cntr, className)}>
      {children}
    </div>
  );
};

export default SafeAreaContainer;
