import React, { memo } from 'react';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CodeBlock from './Code';

const useStyles = makeStyles((theme) => ({
  body: {
    color: theme.palette.grey['900'],
    marginBottom: theme.spacing(3),
  },
  headingWrapper: {
    '&:before': {
      content: "''",
      display: 'block',
      height: '75px',
      marginTop: '-75px',
      // visibility: 'hidden',
    },
  },
  h1: {
    fontSize: '3rem',
    marginBottom: theme.spacing(2),
  },
  h2: {
    fontSize: '2rem',
    marginBottom: theme.spacing(1),
  },
  h3: {
    fontSize: '1.7rem',
    marginBottom: theme.spacing(1),
  },
  h4: {
    fontSize: '1.5rem',
    marginBottom: theme.spacing(1),
  },
  h5: {
    fontSize: '1.4rem',
    marginBottom: theme.spacing(1),
  },
  h6: {
    fontSize: '1.3rem',
    marginBottom: theme.spacing(1),
  },
  ul: {
    marginBottom: theme.spacing(3),
  },
}));

const components = {
  p: (props) => {
    const classes = useStyles();
    return (
      <Typography
        {...props}
        color="textPrimary"
        variant="body1"
        gutterBottom
        className={classes.body}
      />
    );
  },
  h1: (props) => {
    const classes = useStyles();
    return (
      <Typography
        className={classes.h1}
        {...props}
        color="primary"
        component="h1"
        variant="h1"
      />
    );
  },
  h2: (props) => {
    const classes = useStyles();
    return (
      <Typography
        className={clsx(classes.headingWrapper, classes.h2)}
        {...props}
        color="primary"
        component="h2"
        variant="h2"
      />
    );
  },
  h3: (props) => {
    const classes = useStyles();
    return (
      <Typography
        className={classes.h3}
        {...props}
        color="primary"
        component="h3"
        variant="h3"
      />
    );
  },
  h4: (props) => {
    const classes = useStyles();
    return (
      <Typography
        className={classes.h4}
        {...props}
        color="primary"
        component="h4"
        variant="h4"
      />
    );
  },
  h5: (props) => {
    const classes = useStyles();
    return (
      <Typography
        className={classes.h5}
        {...props}
        color="primary"
        component="h5"
        variant="h5"
      />
    );
  },
  h6: (props) => {
    const classes = useStyles();
    return (
      <Typography
        className={classes.h6}
        {...props}
        color="primary"
        component="h6"
        variant="h6"
      />
    );
  },
  blockquote: (() => {
    const Blockquote = (props) => (
      <Paper style={{ borderLeft: '4px solid grey', padding: 8 }} {...props} />
    );
    return memo(Blockquote);
  })(),
  ul: (props) => {
    const classes = useStyles();
    return <Typography {...props} component="ul" gutterBottom className={classes.ul} />;
  },
  ol: (() => {
    const Ol = (props) => <Typography {...props} component="ol" gutterBottom />;
    return memo(Ol);
  })(),
  li: (() => {
    const Li = (props) => <Typography {...props} component="li" />;
    return memo(Li);
  })(),
  table: (() => {
    const Table = (props) => <MuiTable {...props} />;
    return memo(Table);
  })(),
  tr: (() => {
    const Tr = (props) => <TableRow {...props} />;
    return memo(Tr);
  })(),
  td: (() => {
    const Td = ({ align, ...props }) => (
      <TableCell align={align || undefined} {...props} />
    );
    return memo(Td);
  })(),
  tbody: (() => {
    const TBody = (props) => <TableBody {...props} />;
    return memo(TBody);
  })(),
  th: (() => {
    const Th = ({ align, ...props }) => (
      <TableCell align={align || undefined} {...props} />
    );
    return memo(Th);
  })(),
  thead: (() => {
    const THead = (props) => <TableHead {...props} />;
    return memo(THead);
  })(),
  pre: (props) => <div {...props} />,
  code: CodeBlock,
  hr: Divider,
  input: (() => {
    const Input = (props) => {
      const { type } = props;
      if (type === 'checkbox') {
        return <Checkbox {...props} disabled={false} readOnly />;
      }
      return <input {...props} />;
    };
    return memo(Input);
  })(),
  wrapper: (() => {
    const Wrapper = (props) => <div {...props} className="markdown-body" />;
    return memo(Wrapper);
  })(),
};

export default components;
