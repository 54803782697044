import React, { useEffect, useState } from 'react';

// MUI functions
import {
  makeStyles,
} from '@material-ui/core/styles';

import blueGrey from '@material-ui/core/colors/blueGrey';
import indigo from '@material-ui/core/colors/indigo';

import BlobItem from './blob';

interface Props {
  width: number | string
  height: number | string
  ratio: number
}

interface StyleProps {
  width: number | string
  height: number | string
}

type PointCloudType = [number, number][];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    width: (props: StyleProps) => props.width,
    height: (props: StyleProps) => props.height,
  },
}));

const SVGArt = ({ height, width, ratio }: Props) => {
  const classes = useStyles({ height, width });

  const [pointCloud, setPointCloud] = useState<PointCloudType>([]);
  useEffect(() => {
    const bWidth = 100 * ratio;
    const bHeight = 100;
    const cellSize = 20;
    const variance = 10;
    // we divide the canvas into cells and add a few overflow cells to
    // make sure we cover the entire visible area (2 on each side, so 4)
    const colCount = Math.floor(bWidth / cellSize) + 4;
    const rowCount = Math.floor(bHeight / cellSize) + 4;

    // determine bleed values to ensure that the grid is centered within the
    // artboard
    const bleedX = ((colCount * cellSize) - bWidth) / 2;
    const bleedY = ((rowCount * cellSize) - bHeight) / 2;

    // apply variance to cellSize to get cellJitter in pixels
    const cellJitter = cellSize * variance;
    const getJitter = () => (Math.random() - 0.5) * cellJitter;

    const pointCount = colCount * rowCount;

    const halfCell = cellSize / 2;

    const points:[number, number][] = Array(pointCount).fill(null).map((_, i) => {
      const col = i % colCount;
      const row = Math.floor(i / colCount);

      // [x, y, z]
      return [
        -bleedX + col * cellSize + halfCell + getJitter(),
        -bleedY + row * cellSize + halfCell + getJitter(),
      ];
    });
    setPointCloud(points);
  }, []);

  const colorArray = [
    // blueGrey['100'],
    // blueGrey['200'],
    // blueGrey['300'],
    // blueGrey['400'],
    // blueGrey['500'],
    blueGrey['600'],
    blueGrey['700'],
    blueGrey['700'],
    blueGrey['800'],
    blueGrey['800'],
    blueGrey['900'],
    // indigo['100'],
    // indigo['200'],
    // indigo['300'],
    // indigo['400'],
    // indigo['500'],
    // indigo['600'],
    indigo['700'],
    indigo['800'],
    // indigo['900'],
  ];
  return (
    <div className={classes.root}>
      <svg
        viewBox={`0 0 ${ratio * 100} 100`}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        preserveAspectRatio="none"
        id="blobSvg"
      >
        <defs>
          <filter id="shadow">
            <feDropShadow
              dx="-0.2"
              dy="-0.2"
              stdDeviation="3"
              floodColor="black"
              floodOpacity="0.1"
            />
          </filter>
        </defs>
        {pointCloud.map((point) => (
          <BlobItem
            key={`blob-${point[0]}-${point[1]}`}
            colorArray={colorArray}
            point={point}
          />
        ))}
      </svg>
    </div>
  );
};

export default SVGArt;
