import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import {
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  link: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
}));

interface LayoutRootProps {
  className?: string
  to: string
  children: React.ReactNode
}

const LayoutRoot: React.FC<LayoutRootProps> = (
  { children, className, to }: LayoutRootProps,
) => {
  const classes = useStyles();
  return (
    <Link className={clsx(className, classes.link)} to={to}>{children}</Link>
  );
};

export default LayoutRoot;
