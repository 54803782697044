import React from 'react';

interface LayoutRootProps {
  className?: string
  children: React.ReactNode
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }: LayoutRootProps) => (
  <>
    <div className={className}>{children}</div>
  </>
);

export default LayoutRoot;
