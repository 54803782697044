import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// MUI functions
import {
  makeStyles, darken,
} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';

// subsidy logos
// import MCIULogo from '../brand/mciu.jpg';
// import CAMLogo from '../brand/cam.png';

import Logo from '../brand/wxd_logo_white.png';
import Link from './CleanLink';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  footerDark: {
    backgroundColor: darken(theme.palette.secondary.main, 0.3),
    padding: theme.spacing(2),
  },
  whiteText: {
    color: '#fff',
  },
  legalItemDiv: {
    display: 'flex',
  },
  legalItem: {
    marginRight: theme.spacing(2),
  },
  socialItemGrid: {
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  socialItemDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  socialItem: {
    marginLeft: theme.spacing(2),
    color: '#fff',
  },
  categoryHeader: {
    '&:before': {
      content: "''",
      display: 'inline-block',
      width: '1rem',
      height: '1rem',
      backgroundColor: theme.palette.secondary.main,
      backgroundPositionX: '0%',
      backgroundPositionY: '0%',
      backgroundRepeat: 'repeat',
      backgroundAttachment: 'scroll',
      backgroundImage: 'none',
      backgroundSize: 'auto',
      backgroundOrigin: 'padding-box',
      backgroundClip: 'border-box',
      marginRight: '0.625rem',
    },
    color: theme.palette.secondary.contrastText,
  },
  categoriesDiv: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    justifyContent: 'flex-end',
  },
  categoriesItem: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
    },
  },
  subsidiesDiv: {
    color: theme.palette.secondary.contrastText,
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  subsidiesImgDiv: {
    maxWidth: '100%',
    listStyle: 'none',
    float: 'right',
    '& > *': {
      display: 'inline-block',
      // flexWrap: 'nowrap',
      marginLeft: theme.spacing(2),
    },
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  logoAndCopyRight: {
    marginBottom: theme.spacing(3),
  },
  grantLogo: {
    height: '80px',
  },
}));

type YAMLType = {
  footerYaml: {
    social: {
      item: {
        network: string
        url: string
      }
    }[]
  }
};

const Footer = () => {
  const classes = useStyles();

  const { footerYaml: YAML }: YAMLType = useStaticQuery(graphql`
  {
    footerYaml {
      social {
        item {
          network
          url
        }
      }
    }
  }
  
  `);

  const renderNetworkIcon = (network: string) => {
    switch (network) {
      case 'github':
        return <GitHubIcon />;
      case 'facebook':
        return <FacebookIcon />;
      case 'twitter':
        return <TwitterIcon />;
      case 'youtube':
        return <YouTubeIcon />;
      case 'linkedin':
        return <LinkedInIcon />;
      default:
        return <LinkedInIcon />;
    }
  };
  return (
    <>
      <footer className={classes.footer}>
        <Container>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
          >
            <Grid item xs={12} md={6} lg={2} className={classes.logoAndCopyRight}>
              <img
                src={Logo}
                alt="Logo"
                height="55px"
              />
              <Typography variant="subtitle2" className={classes.whiteText}>
                &copy; 2019-
                {new Date().getFullYear()}
                {' '}
                Collisio Technologies S.L.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={10} className={classes.categoriesDiv}>
              
            </Grid>

          </Grid>

        </Container>
      </footer>
      <footer className={classes.footerDark}>
        <Container>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
          >
            <Grid item xs={12} md={6} lg={4} className={classes.legalItemDiv}>
              {/* yo */}
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.socialItemGrid}>
              <Typography variant="overline" color="inherit">
                follow us
              </Typography>
              <div className={classes.socialItemDiv}>

                {YAML.social.map((socialItem) => (
                  <a
                    key={socialItem.item.url}
                    href={socialItem.item.url}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.socialItem}
                  >
                    {renderNetworkIcon(socialItem.item.network)}
                  </a>
                ))}
              </div>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
